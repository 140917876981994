import $ from "jquery";
import 'lightbox2';
import '../../global/js/cms.js';

$(document).ready(function() {
    var image = 1;
    $('.halloffame a[href$=jpg]').each(function() {
        $(this).attr('data-lightbox', 'image-' + image++);
    });

    $('[data-open]').css('cursor', 'pointer').click(function() {
        var tab = $(this).attr('data-open');
        $('.tab').hide();
        $('.' + tab).show();

    });
});

var Spellbook = {
    spellbook: $('#spellbook'),
    init: function() {
        if(Spellbook.spellbook.length == 0) {
            return;
        }

        $('#spellbook').width($('#spellbook > img').width());
        $('#spellbook a').each(function() {
            var x = $(this).attr('data-x') + 'px';
            var y = $(this).attr('data-y') + 'px';
            $(this).css('top', y).css('left', x);
        }).click(function(e) {
            e.preventDefault();

            var spell = $(this);
            Spellbook.activateSpell(spell);

            return false;
        });
    },
    activateSpell: function(spell) {

        var pageContent = $('.page-content');
        if(spell.attr('data-active') == 'all') {
            this.spellbook.find('a').css('opacity', 1);
            pageContent.find('h3').css('display', 'block');
            pageContent.find('table.spell').css('display', 'block');
        } else {
            this.spellbook.find('a').css('opacity', 0);
            spell.css('opacity', 1);
            pageContent.find('h3').css('display', 'none');
            pageContent.find('table.spell').each(function () {
                if ($(this).attr('id') == 'spell-' + spell.attr('data-active')) {
                    $(this).css('display', 'block').prevAll("h1:first").css('display', 'block');
                } else {
                    $(this).css('display', 'none');
                }
            });
        }
    }
};

var Creatures = {
    list: $('.creatures'),
    init: function() {

        $('.creature').append(
            $('<tr />').append(
                $('<th />').attr('colspan', 4).append(
                    $('<a />').attr('href', '#top').text('Wróć do listy stworzeń')
                )
            )
        );
        this.list.find('a').click(function(e) {
            e.preventDefault();
            $('.creature').hide();
            var creature = $(this).attr('href').substr(1)
            Creatures.showCreature(creature);

            window.location.hash = creature;
            return false;
        });

        $('.creature').hide(0, function() {
            if(window.location.hash) {
                var c = window.location.hash.substr(1);
                var creatureId = '#creature-' + c;
                $(creatureId).show();

            }
        });


    },
    showCreature: function(creature) {
        var creatureId = '#creature-' + creature;
        $(creatureId).show(0, function() {
            $('html,body').animate({scrollTop: $(creatureId).offset().top}, 'fast');
        });


    }
};


$(window).on('load', function() {
    $("#spellbook > img").one("load", function() {
        console.log('test');
        Spellbook.init();
    }).each(function() {
        console.log('a');
        if(this.complete) {
            $(this).trigger('load');
        }
    });


    Creatures.init();
});
